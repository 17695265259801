<template>
    <st-section
        :header="$t('DECISION.LIST.TITLE')"
        customClass="list-section"
        id="decision-target"
        validate
        :wasValidated="wasValidated"
        :validation="validationStatus">
        <template #body>
            <carousel-3d
                :controls-visible="true"
                :width="400"
                :height="230"
                :space="300"
                :count="itemsComputed.length"
                dispay="3"
                ref="decisionCarousel">
                <slide v-for="(item, i) in itemsComputed" :index="i" :key="i">
                    <div class="carousel-card decision-card">
                        <application-decision-item-card v-if="!item.isPlaceholder"
                            @edit="onEdit"
                            @delete="onDelete"
                            :item="item"
                            :index="i"
                            :canEdit="canEdit"
                        ></application-decision-item-card>
                        <div class="card-user-placeholder" v-else>
                            <i class="fas fa-gavel"></i>
                        </div>
                    </div>
                </slide>
            </carousel-3d>
            <div class="d-flex list-footer">
                <div class="col-10 d-flex align-items-center px-0">
                    <span>{{ total }}</span>
                </div>
                <div class="col-2 d-flex justify-content-end px-0" v-if="canEdit">
                    <st-button :callback="openDecisionModal" variant="link">
                        <span class="d-flex align-items-center">
                            <i class="fas fa-plus"></i>
                            {{ $t("DECISION.BUTTON.ADD_DECISION") }}
                        </span>
                    </st-button>
                </div>
            </div>

            <application-decision-modal
                ref="decisionModal"
                :item="selectedEntity"
                @processDecision="processDecision"
                @cancel="cancel"
            ></application-decision-modal>

        </template>
    </st-section>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from "vuex";
import { Carousel3d, Slide } from 'vue-carousel-3d';
import ApplicationDecisionModal from "@/modules/applications/components/decision/ApplicationDecisionModal";
import ApplicationDecisionItemCard from '@/modules/applications/components/decision/ApplicationDecisionItemCard';

export default {
    name: "ApplicationDecisionList",
    components: {
        ApplicationDecisionModal,
        ApplicationDecisionItemCard,
        Carousel3d,
        Slide
    },
    props: {
        viewMode: {
            type: Boolean,
            default: false
        },
        wasValidated: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            items: [],
            selectedEntity: null,
            selectedEntityIndex: null,
            canEdit: false,
            canEditStatus: ['pending', 'approved']
        };
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
        }),
        total() {
            return this.$tc('DECISION.TOTAL_TEXT', this.items.length, { total: this.items.length });
        },
        itemsComputed() {
            const countItems = this.items.length;
            if (!countItems >= 3) return [...this.items];
            const previewItems = 3;
            let visibleItems = [];
            if (countItems > 0) {
                visibleItems = [...this.items];
            }
            const placeholderItems = previewItems - countItems;
            for(let i = 0; i < placeholderItems; i+=1) {
                visibleItems.push({
                    isPlaceholder: true,
                });
            }

            return visibleItems;
        },
        validationStatus() {
            return this.items.length ? 'success' : 'error';
        }
    },
    methods: {
        openDecisionModal() {
            this.$refs.decisionModal.show();
        },
        processDecision(data) {
            const isEdit = Object.keys(data).some((el) => el === 'index');
            if (isEdit) {
                Vue.set(this.items, data.index, data);
            } else {
                this.items.push({
                    ...data,
                    index: this.items.length
                });
                this.goToSlide(this.items.length - 1);
            }

            this.$emit('changeList', this.items, 'decisions');
            this.selectedEntity = null;
        },
        onEdit({item, index}) {
            this.selectedEntity = item;
            this.selectedEntity.index = index;
            this.selectedEntityIndex = index;
            this.$refs.decisionModal.show();
        },
        onDelete({index}){
            this.items.splice(index, 1);
            this.goToSlide(0);
        },
        cancel() {
            this.selectedEntity = null;
            this.selectedEntityIndex = null;
        },
        goToSlide(index) {
            this.$refs.decisionCarousel.goSlide(index);
        }
    },
    created() {
        this.items = [];

        if (this.$route.params.id ) {
            this.items = this.record?.form_staff?.decisions ? this.record.form_staff.decisions : [];

            this.canEdit = this.canEditStatus.includes(this.record?.internal_status) ? true : this.viewMode;
        }
    },
};
</script>
