<template>
    <form class="form"
        novalidate="novalidate"
        id="st_modal_decision_form"
        @submit.stop.prevent="onSubmit()"
    >
        <div class="form-group">
            <label>
                {{ $t('DECISION.CARD.PURPOSE_LABEL') }} *
            </label>
            <div>
                <st-autocomplete
                    v-model="model[fields.purpose_id.name]"
                    :options="fields.purpose_id.options"
                    :ref="fields.purpose_id.name"
                    :name="fields.purpose_id.name"
                    @change="onChange($event, fields.purpose_id, true)"
                >
                </st-autocomplete>
            </div>
        </div>
        <div class="form-group">
            <label>
                {{ $t('DECISION.CARD.DECISION_LABEL') }} *
            </label>
            <div>
                <st-autocomplete
                    v-model="model[fields.decision_id.name]"
                    :options="fields.decision_id.options"
                    :ref="fields.decision_id.name"
                    :name="fields.decision_id.name"
                    @change="onChange($event, fields.decision_id)"
                >
                </st-autocomplete>
            </div>
        </div>
        <div class="form-group">
            <label>
                {{ $t('DECISION.CARD.EXTENSION_LABEL') }}
            </label>
            <div>
                <st-autocomplete
                    v-model="selectedExtension"
                    :options="extensionOptions"
                    ref="extension_id"
                    name="extension_id"
                    valueField="id"
                    textField="name"
                >
                </st-autocomplete>
            </div>
        </div>
        <div class="d-flex justify-content-between mt-6">
            <st-button size="large" variant="link" :callback="cancel">
                {{ $t("GENERAL.BUTTON.CANCEL") }}
            </st-button>
            <st-button size="large" :callback="submit">
                {{ $t("GENERAL.BUTTON.SAVE") }}
            </st-button>
        </div>
    </form>
</template>

<script>
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { mapActions, mapGetters } from 'vuex';

import { FormSchema } from '@/shared/form/form-schema';
import { ApplicationDecisionModel } from '@/modules/applications/models/application-decision-model';

const { fields } = ApplicationDecisionModel;

const formSchema = new FormSchema([
    fields.purpose_id,
    fields.decision_id,
])

export default {
    name: 'ApplicationDecisionForm',
    props: {
        item: {
            type: Object,
            default: () => {}
        },
        editMode: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        'model.purpose_id'(newValue, oldValue) {
            if (newValue === oldValue) return false;
            this.model.target_id = '';
            this.model.target_name = '';
            this.model.description = '';
        }
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: null,
            fields: fields,
            selectedExtension: '',
        };
    },
    computed: {
        extensionOptions() {
            if (!this.model.decision_id) return [];
            const selectedDecision = this.fields.decision_id.options.find((el) => el.value === this.model.decision_id);
            const extensions = selectedDecision?.extensions || [];
            if (!extensions.length) return [];
            return extensions;
        }
    },
    methods: {
        cancel() {
            this.$emit('cancelForm');
        },
        submit() {
            this.fv.validate()
            .then((status) => {
                if (status === 'Valid') {
                    const payload = {};
                    if (this.model?.decision_id) {
                        const selectedDecision = this.fields.decision_id.options.find((el) => el.value === this.model.decision_id);
                        payload.decision_id = this.model.decision_id;
                        payload.decision_name = selectedDecision.name;
                        payload.description = selectedDecision.description;
                        payload.target_id = selectedDecision.target_id;
                        payload.target_name = selectedDecision.target_name;
                    }
                    if (this.model?.purpose_id) {
                        payload.purpose_id = this.model.purpose_id;
                        payload.purpose_name = this.fields.purpose_id.options.find((el) => el.value === this.model?.purpose_id)?.text;
                    }
                    if (this.selectedExtension) {
                        payload.extension_id = this.selectedExtension;
                        payload.extension_name = this.extensionOptions.find((el) => el.id === this.selectedExtension)?.name;
                    }
                    if (this.item) {
                        payload.index = this.item.index;
                    }
                    
                    this.$emit('submitForm', payload);
                }
            });
        },
        onChange(value, field, validateChild) {
            this.selectedExtension = "";
            this.fv.revalidateField(field.name, true);
            if (field?.children && validateChild) {
                field.children.forEach((child) => {
                    this.model[child.name] = "";
                    const el = this.fields?.[child.name];
                    if (el) {
                        el.fetchParams = { [child.key]: value };
                        el.updateOptions();
                        if (this.fv.fields[child.name]) {
                            this.fv.revalidateField(child.name, true);
                        }
                    }
                });
            }
        },
    },
    created() {
        this.model = formSchema.initialValues(this.item);
        if (this.item) {
            this.selectedExtension = this.item?.extension_id || '';
            this.model.extension_name = this.item?.extension_name || '';
        }
    },
    mounted() {
        this.fv = createFormValidation('st_modal_decision_form', this.rules);
    },
};
</script>
